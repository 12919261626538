.wrapper {
    display: grid;
    grid-template-columns: 80px auto 80px;
    max-width: 800px;
    margin: 0 auto;
    min-height: 120px;
    gap: 30px;
  }
  
  .content {
    background-color: white;
    padding: 10px;
    display: grid;
    place-items: center;
    border-radius: 5px;
  }
  
  .button {
    border: none;
    background-color: #6cf5c2;
    border-radius: 5px;
  }
  
  .button:hover {
    background-color: #1fffad;
    cursor: pointer;
  }
  
  .icon {
    width: 20px;
    height: 20px;
  }
  
  .title {
    margin-bottom: 10px;
    color: #2c2851;
    font-family: "Montserrat", sans-serif;
  }
  
  .text {
    color: #85819f;
  }
  
  @media only screen and (max-width: 560px) {
    .wrapper {
      grid-template-columns: 1fr;
    }
  
    .button {
      min-height: 50px;
    }
  }