.wrapper {
    display: grid;
    gap: 30px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    min-height: 120px;
    border-radius: 10px;
    background-color: white;
  }
  
  .image {
    width: 80px;
    height: 80px;
  }
  
  .info {
    padding: 10px;
    display: grid;
    place-items: center;
  }
  
  .title {
    margin-bottom: 10px;
    color: #2c2851;
    font-family: "Montserrat", sans-serif;
  }
  
  .description {
    color: #85819f;
  }


  
  @media only screen and (max-width: 560px) {
    .card {
      grid-template-columns: 1fr;
    }
  }